<template>
  <b-form-group
    label="İl"
    label-for="id_cities"
  >
    <v-select
      id="id_cities"
      v-model="filterData.id_cities"
      :options="cities"
      label="title"
      :reduce="city => city.id"
      placeholder="Seçiniz"
      :disabled="cities.length === 0"
    />
  </b-form-group>
</template>

<script>
import {
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'Cities',
  components: {
    BFormGroup,
    vSelect,
  },
  computed: {
    filterData() {
      return this.$store.getters['suppliers/filterData']
    },
    cities() {
      return this.$store.getters['cities/dataList']
    },
  },
}
</script>
